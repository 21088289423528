/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* import React from 'react';
import FirebaseContainer from './src/containers/firebasecontainer';

export const wrapRootElement = ({ element }) => (
  <FirebaseContainer>{element}</FirebaseContainer>
);
 */
require('typeface-nunito');
